export default {
  "main_links": {
    "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курсы"])},
    "about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О нас"])},
    "free_prezentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пробный урок"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты"])}
  },
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
  "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Позвонить"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы на шаг отстаете от достижения своей цели."])},
  "hero_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["И пора сделать этот первый шаг!"])},
  "socials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cоциальные сети"])},
  "hero_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Записаться на курс"])},
  "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страница не найдена"])},
  "about_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наша отличительная черта от других учебных центров - это совершенство учебника, понятный стиль преподавания, потому что особое внимание уделяется каждому ученику. Качество в целом, хотя и значительно превосходит цену, также может мотивировать студентов выбирать IT-Labs."])},
  "connect_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Связаться с нами"])},
  "tabs": {
    "advantages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преимущества"])},
    "students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Студентам"])},
    "parents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Родителям"])},
    "novza": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новза"])},
    "beruniy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Беруний"])}
  },
  "tab_texts": {
    "advantages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы делаем всё, чтобы выпускников учебного центра \"IT-LABS\" принимали наработу сразу после получение сертификата. Для этого каждый год улучшаем программу, зовём к себе лучших преподавателей которые стремятся обучать всё лучше и лучше.        Прежде чем запустить программу обучения, обсуждаем её с компаниями и экспертами. Это нужно, чтобы в знаниях студентов и самой программе не было слабых мест."])},
    "students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы помогаем нашим лучшим ученикам найти работу. <br>   Мы привлекли наставников, которые прошли этот путь программиста, чтобы студенты не споткнулись в пути по которым они собираются идти, и они поделятся с вами не только качественными уроками, но и своим опытом. Это может стать большим стимулом для вас что бы быстрее развиваться на своем пути."])},
    "parents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы уделяем особое внимание качественному образованию детей, каждому ребенку отдельный подход, чтобы они получали образование и понимали урок. Это помогает нам улучшить качество урока и структуру прохождения, а также повысить интерес ребенка к уроку.Из ходя из этого нет нужды беспокоиться об образовании и качестве обучения ребенка."])}
  },
  "top_info": {
    "video_suptitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О нас"])},
    "video_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы даём нашим студентам шанс стать другими людьми"])},
    "video_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si sine causa, mox videro; interea hoc epicurus in bonis sit numeranda nec me tamen laudandis.О нас"])},
    "form_suptitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Записаться на курс"])},
    "form_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На какой курс вы хотите?"])},
    "courses_suptitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наши курсы"])},
    "courses_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы предоставляем лучшие курсы на рынке."])},
    "courses_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["После прохождения этих курсов вы, освоите целую профессию и сможете построить свою карьеру. Да, кстати, эти курсы считаются востребованными не только сейчас но и в будущем."])},
    "questions_suptitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вопросы"])},
    "questions_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Часто задаваемые вопросы?"])},
    "about_suptitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почему мы?"])},
    "about_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почему нас выбирают?"])},
    "employee_suptitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наша команда"])},
    "employee_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наша команда"])}
  },
  "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваше имя"])},
  "phone_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш номер телефона"])},
  "send_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить"])},
  "questions": {
    "question1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Можно ли оплатить курс в рассрочку?"])},
    "question2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Какова сила вашего сертификата? Он официальный? Он где-то зарегистрирован?"])},
    "question3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как проходит курс?"])},
    "question4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Где вы расположены?"])}
  },
  "answers": {
    "answer1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для удобства студентов мы сделали самые низкие цены и можно будет оплатить курс каждый месяц по частям"])},
    "answer2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сертификат-это документ, подтверждающий, что вы прошли курс. У нас есть все необходимые лицензии. На сертификате стоит наша печать и подпись директора. <br> Для нас важнее всего результаты наших выпускников и то, как они осваивают курс. Мы дорожим своей репутацией и гордимся достижениями наших выпускников. Если мы видим, что у человека нет мотивации учиться или ему действительно не нужен этот курс, мы не записываем его на наш курс."])},
    "answer3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наши курсы длятся 6 месяцев (Программирование), занятия проходят 3 раза в неделю по 1,5 - 2 часа. <br> Мы добавляем всех студентов курса в telegram-канал, и после урока материалы отправляются в группу. <br> Учителя и помощники будут поддерживать с вами связь на протяжении всего курса, чтобы они могли ответить на любые ваши вопросы и оказать помощь, когда это необходимо. <br>  Всего в группе обучается 10-14 человек. Это может быть большим вкладом в то, чтобы вы могли усваивать урок по полной."])},
    "answer4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы находимся рядом с метро Новза (Траст банк) <br> Подробную локацию и схему проезда мы можем выслать вам в телеграм. <br> +998(98) 121-98-08"])}
  },
  "location_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свяжитесь с нами"])},
  "location_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Связь с нами, вы получите подробные ответы на ваши вопросы."])},
  "location_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Понедельник-Суббота 14:00-20:00"])},
  "location_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["город Ташкент, Чиланзарский район, квартал Е, дом 9 <br> город Ташкент,  Алмазарский район, Беруний 7,"])},
  "footer_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torquem detraxit hosti et quidem se repellere, idque instituit docere."])},
  "footer_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщение"])}
}