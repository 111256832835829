export default {
  "main_links": {
    "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurslar"])},
    "about_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biz haqimizda"])},
    "free_prezentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bepul dars"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])}
  },
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ortga"])},
  "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qo'ng'iroq qilish"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siz, maqsadingizga erishishdan bir qadam orqadasiz "])},
  "hero_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["va bu birinchi qadamni tashlash vaqti keldi!"])},
  "socials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ijtimoiy tarmoqlarimiz"])},
  "hero_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursga yozilish"])},
  "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bunday sahifa topilmadi"])},
  "about_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boshqa o'quv markazlardan ajralib turadigan taraflarimiz bu - O'quv darslikning mukammaligidir, o'qitish uslubi tushunarlidir negaki har bir o'quvchiga alohida e'tibor. Umumman olganda sifat, narxga nisbatan ancha ustunlik qilishi ham, o'quvchilar IT-Labs ni tanlashiga turtki bo'la oladi."])},
  "connect_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biz bilan bog'lanish"])},
  "tabs": {
    "advantages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afzalliklari"])},
    "students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talabalarga"])},
    "parents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ota-onalarga"])},
    "novza": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novza"])},
    "beruniy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beruniy"])}
  },
  "tab_texts": {
    "advantages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biz \"IT-LABS\" o'quv markazi bitiruvchilarini sertifikat olgandan so'ng darhol ishga qabul qilinishlari uchun astoydil harakat qilamiz. Buning uchun har yili biz dasturni takomillashtiramiz, nafaqati bilimi balki o'qitish uslubiga ham alohida e'tibor beramiz. O'quv dasturini boshlashdan oldin biz uni kompaniyalar va mutaxassislar bilan muhokama qilamiz. Bu talabalar bilimida va  yozayotgan kodlarida zaif tomonlar bo'lmasligi uchun muhum ahamiyatga ega."])},
    "students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biz o'zimizning talabalarni o'ylagan holda, eng yaxshi talabalarimizni ish bilan taminlashga ko'maklashamiz.<br>  Talabalar bosib o'tmoqchi bo'lgan yo'llairdagi tirbantliklarga qoqilmasliklari uchun, shu yo'lni bosib o'tgan ustozlarni jalb qilganmiz va ular nafaqat sifatli darslarni balki o'zlarining tajribalari bilan ham siz bilan bo'lishadilar. Bu esa o'z o'rnida sizni tezroq rivojlanishingizga katta turtki bo'la oladi."])},
    "parents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biz bolalarning sifatli ta'limi uchun, har bir bolaga alohida e'tibor qaratamiz ya'ni, ularning ta'lim olishi va darsni tushunayotganliklarini ko'zdan kechiramiz. Bu bizga darsning sifatini va o'tish strukturasini yaxshilashga, bolaning esa darsga bo'lgan qiziqishini oshirishga yordam beradi. Shunday ekan bolaning ta'limidan va o'qish sifatidan havotir olishga o'rin yo'q"])}
  },
  "top_info": {
    "video_suptitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biz haqimizda"])},
    "video_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biz talabalarimizga boshqa inson bo'lishlariga imkon beramiza"])},
    "video_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si sine causa, mox videro; interea hoc epicurus in bonis sit numeranda nec me tamen laudandis.О нас"])},
    "form_suptitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursga yozilish"])},
    "form_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qaysi kursga yozilmoqchisiz?"])},
    "courses_suptitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bizning kurslar"])},
    "courses_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biz zamon talabidagi eng yaxshi kurslarini taklif etamiz."])},
    "courses_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bu kurslarni tugatgandan so'ng siz, butun boshli kasbni egallagan bo'lasiz va bu kasb orqali o'z karyerangizni qura olasiz. Ha, aytgancha bu kurslar kelajakdagi talabdor kurslar hisoblanadi."])},
    "questions_suptitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Savollar"])},
    "questions_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eng ko'p beriladigan savollar"])},
    "about_suptitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nega biz?"])},
    "about_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nega bizni tanlashadi?"])},
    "employee_suptitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bizning jamoa"])},
    "employee_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bizning jamoa"])}
  },
  "name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ismingiz"])},
  "phone_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon raqamingiz"])},
  "send_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yuborish"])},
  "questions": {
    "question1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To'lovni qismlarga bo'lib to'lash mumkinmi?"])},
    "question2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sizning sertifikatingiz qanday kuchga ega? U rasmiymi? U biron bir joyda ro'yxatdan o'ganmi?"])},
    "question3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurs qanday o'tiladi?"])},
    "question4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qayerda joylashgansizlar?"])}
  },
  "answers": {
    "answer1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talabalarga qulaylik yaratish uchun biz eng optimal narxlarni qo'ydik va kursni har oy qismlarga bo'lib to'lash mumkin bo'ladi."])},
    "answer2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sertifikat-bu kursda o'qiganligingizni tasdiqlovchi hujjat. Bizda barcha zarur litsenziyalar mavjud. Sertifikatda bizning muhrimiz va direktorning imzosi mavjud. <br> Biz uchun eng muhimi, bitiruvchilarimizning natijalari va ular kursni qanday o'zlashtirishlari. Biz o'z obro'imizni qadrlaymiz va bitiruvchilarimizning yutuqlari bilan faxrlanamiz. Agar biror kishi o'qishga undamaganini yoki aslida bu kursga muhtoj emasligini ko'rsak, biz uni kursimizga  yozmaymiz."])},
    "answer3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurslarimiz 6 oy (Dasturlash) davom etadi, darslar haftasiga 3 marta 1,5 - 2 soat dan bo'lib o'tadi. <br> Biz barcha kurs talabalarini telegram kanalga qo'shamiz va darsdan so'ng materiallar guruhga yuboriladi. <br>Kurs davomid o'qituvchi va yordamchilar  siz bilan aloqada bo'lib turadilar, ular sizning har qanday savolingizga javob bera oladi va kerak bo'lganda yordam berishadi.<br>Umuman olganda, guruhda 10-14 kishi tahsil oladi. Bu esa sizni yaxshi o'zlashtirishingiz uchun katta hissa bo'la oladi"])},
    "answer4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biz Novza metrosi yaqinida joylashganmiz (TRUST-BANK) <br> Batafsil joylashuv va yo'l sxemasini telegram orqali yuborishimiz mumkun. Quyida telefon nomerni yozib qoldiramiz. <br>+998(98) 121-98-08"])}
  },
  "location_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biz bilan bog'laning"])},
  "location_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bog'lanish orqali o'zingizni qiziqtirayotgan savollarga javob olishingiz mumkun."])},
  "location_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dushanba-Shanba 14:00-20:00"])},
  "location_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toshkent shahar, Chilonzor tumani E-kvartal 9-uy <br> Toshkent shahar, Olmazor tumani Beruniy 7"])},
  "footer_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torquem detraxit hosti et quidem se repellere, idque instituit docere."])},
  "footer_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manzil"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xabar qoldiring"])}
}